export default defineNuxtRouteMiddleware(async () => {
  const nuxtApp = useNuxtApp();

  if (import.meta.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) {
    return;
  }

  const cartStore = useCartStore();

  await cartStore.refresh();

  if (cartStore.data?.items?.length) {
    return;
  }

  if (import.meta.client) {
    const { notify } = useQuasar();

    notify({
      type: 'negative',
      message: 'Your cart is empty.',
    });
  }

  return abortNavigation(
    createError({
      statusCode: 404,
      message: 'Your cart is empty.',
    }),
  );
});
